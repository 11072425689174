<template>
  <div class="more-page">
    <ul>
      <li>
        <router-link to="/">
          <h2>On Air</h2>
          <p>Current show</p>
        </router-link>
      </li>
      <li>
        <router-link to="/shows">
          <h2>Schedule</h2>
          <p>Explore our shows.</p>
        </router-link>
      </li>
      <!--
      <li>
        <a href="https://atxsoulradio.com" target="_blank">
          <h2>About</h2>
          <p>About us.</p>
        </a>
      </li>
      -->
    </ul>
  </div>
</template>

<script>

export default {
  name: 'MorePage',
  components: {

  },
  computed: {

  }
}

</script>
